import React, { useState } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';

// import ProyectosDataGrid from "../components/ProyectosDataGrid";
// import PdfForm from "../components/PdfForm";

import FillPdfForm from "../components/FillPdfForm";
import FillPdfFormPrueba from "../components/FillPdfFormPrueba";
import DownloadIcon from '@mui/icons-material/Download';


import ProyectosDataGrid from "../componentes/ProyectosDataGrid";
// import CrearProyecto from "../componentes/CrearProyecto";
import ProyectoForm from "../componentes/ProyectoForm";


function TabPanel({ children, value, index }) {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box p={3}><Typography>{children}</Typography></Box>}
        </div>
    );
}

const CalculaAhorro = () => {


    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        ...theme.applyStyles('dark', {
            backgroundColor: '#1A2027',
        }),
    }));

    return (
        <>
            <Box sx={{ padding: 2 }}>
                <p style={{ fontSize: "42px", textAlign: "center" }}>
                    Calculadora de  Ahorro Energético
                </p>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    centered
                >
                    <Tab value={0} label="Nuevo" />
                    <Tab value={1} label="Mis calculos" />
                    <Tab value={3} label="Ayuda" />
                    <Tab value={4} label="prueba" />
                </Tabs>


                <TabPanel value={value} index={0}>
                    <Grid container spacing={1}>
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Item>
                                <p style={{ fontSize: "14px", textAlign: "center" }}>
                                    dasdASBienvenido a su área de usuario de la Herramienta de Cálculo de Certificados de Ahorro de Energía de MeCAE. Desde este espacio podrás crear un nuevo proyecto, o volver a abrir un proyecto previamente introducido para, si es necesario, modificarlo, completarlo, consultarlo o incluso acceder a determinadas funcionalidades (por ejemplo, imprimir o descargar resúmenes).
                                </p>
                            </Item>
                        </Grid>
                        <ProyectoForm />
                    </Grid>
                </TabPanel>


                <TabPanel value={value} index={1}>
                    <Grid container spacing={5}>
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Item>
                                <p style={{ fontSize: "14px", textAlign: "center" }}>
                                    Bienvenido a su área de usuario de la Herramienta de Cálculo de Certificados de Ahorro de Energía de MeCAE. Desde este espacio podrás crear un nuevo proyecto, o volver a abrir un proyecto previamente introducido para, si es necesario, modificarlo, completarlo, consultarlo o incluso acceder a determinadas funcionalidades (por ejemplo, imprimir o descargar resúmenes).
                                </p>
                            </Item>

                            <ProyectosDataGrid />
                        </Grid>
                    </Grid>
                </TabPanel>


                <TabPanel value={value} index={2}>
                    <Grid container spacing={5}>
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Item>
                                <p style={{ fontSize: "14px", textAlign: "center" }}>
                                    dasdASBienvenido a su área de usuario de la Herramienta de Cálculo de Certificados de Ahorro de Energía de MeCAE. Desde este espacio podrás crear un nuevo proyecto, o volver a abrir un proyecto previamente introducido para, si es necesario, modificarlo, completarlo, consultarlo o incluso acceder a determinadas funcionalidades (por ejemplo, imprimir o descargar resúmenes).
                                </p>
                            </Item>
                        </Grid>
                        <FillPdfForm />
                    </Grid>
                </TabPanel>


                <TabPanel value={value} index={3}>
                    <Grid container spacing={5}>
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Item>
                                <p style={{ fontSize: "14px", textAlign: "center" }}>
                                    ssBienvenido a su área de usuario de la Herramienta de Cálculo de Certificados de Ahorro de Energía de MeCAE. Desde este espacio podrás crear un nuevo proyecto, o volver a abrir un proyecto previamente introducido para, si es necesario, modificarlo, completarlo, consultarlo o incluso acceder a determinadas funcionalidades (por ejemplo, imprimir o descargar resúmenes).
                                </p>
                            </Item>
                        </Grid>
                        <FillPdfForm />
                    </Grid>
                </TabPanel>


                <TabPanel value={value} index={4}>
                    <Grid container spacing={5}>
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Item>
                                <p style={{ fontSize: "14px", textAlign: "center" }}>
                                    dddBienvenido a su área de usuario de la Herramienta de Cálculo de Certificados de Ahorro de Energía de MeCAE. Desde este espacio podrás crear un nuevo proyecto, o volver a abrir un proyecto previamente introducido para, si es necesario, modificarlo, completarlo, consultarlo o incluso acceder a determinadas funcionalidades (por ejemplo, imprimir o descargar resúmenes).
                                </p>
                            </Item>
                        </Grid>
                        <FillPdfFormPrueba />
                    </Grid>
                </TabPanel>

            </Box>
        </>
    );
};

export default CalculaAhorro;
