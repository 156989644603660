const Comunidades = 
[
    { comunidad: "Andalucía", poblacion: 8500000 },
    { comunidad: "Cataluña", poblacion: 7700000 },
    { comunidad: "C. Madrid", poblacion: 6700000 },
    { comunidad: "C. Valenciana", poblacion: 5000000 },
    { comunidad: "Galicia", poblacion: 2700000 },
    { comunidad: "C. y León", poblacion: 2400000 },
    { comunidad: "P. Vasco", poblacion: 2200000 },
    { comunidad: "Canarias", poblacion: 2200000 },
    { comunidad: "C. La Mancha", poblacion: 2000000 },
    { comunidad: "R. Murcia", poblacion: 1500000 },
    { comunidad: "Aragón", poblacion: 1300000 },
    { comunidad: "I. Baleares", poblacion: 1200000 },
    { comunidad: "Extremadura", poblacion: 1000000 },
    { comunidad: "Asturias", poblacion: 1000000 },
    { comunidad: "Navarra", poblacion: 600000 },
    { comunidad: "Cantabria", poblacion: 600000 },
    { comunidad: "La Rioja", poblacion: 300000 },
    { comunidad: "Ceuta", poblacion: 200000 },
    { comunidad: "Melilla", poblacion: 200000 }
  ];

  export default Comunidades;