// Documentation.js
import React from 'react';

const Blog = () => {
  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <iframe
        src="mkdocsBlog/site/blog/" // Path to your MkDocs documentation
        
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Blog"
      />
    </div>
  );
};

export default Blog;
