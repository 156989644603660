const NotFound = () => {
  return (
    <div className="explore">
      <header>
        <p className="pageHeader">Oops!</p>
        <h3>Page not found.</h3>
      </header>
    </div>
  );
};

export default NotFound;
