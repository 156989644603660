// import StockChart from '../props/StockChart';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// figuras

import SessionsChart from '../panel/SessionsChart';
import ChartUserByCountry from '../panel/ChartUserByCountry';
import PageViewsBarChart from '../panel/PageViewsBarChart';
import HighlightedCard from '../panel/HighlightedCard';
import StatCard from '../panel/StatCard';
import ListingsDataGrid from '../panel/ListingsDataGrid';
import ConveniosDataGrid from '../panel/ConveniosDataGrid';


const data = [
    {
        title: 'Users',
        value: '14k',
        interval: 'Last 30 days',
        trend: 'up',
        data: [
            200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
            360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
        ],
    },
    {
        title: 'Conversions',
        value: '325',
        interval: 'Last 30 days',
        trend: 'down',
        data: [
            1640, 1250, 970, 1130, 1050, 900, 720, 1080, 900, 450, 920, 820, 840, 600, 820,
            780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 360, 300, 666,
        ],
    },
    {
        title: 'Event count',
        value: '200k',
        interval: 'Last 30 days',
        trend: 'neutral',
        data: [
            500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
            520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
        ],
    },
];


const Panel = () => {
    return (
        <Card variant="outlined" sx={{ width: '100%' }}>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid size={{ xs: 12 }}>
                        <header>
                            <p style={{ fontSize: "42px", textAlign: "center" }}>
                                Mercado de Ahorros Energéticos.
                            </p>
                            <p style={{ fontSize: "12px", textAlign: "center" }}>
                                Sistema de Certificados de Ahorro Energético (CAE)
                            </p>
                            <p style={{ textAlign: "center" }}>
                                Plataforma de Intermediación.
                            </p>
                        </header>
                    </Grid >
                    <Grid size={{ xs: 12, md: 3 }}>
                        <HighlightedCard />
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <StatCard {...data[1]} />
                    </Grid>

                    <Grid size={{ xs: 12, md: 6 }}>
                        <ChartUserByCountry />
                    </Grid>
                    {/* <Grid size={{ xs: 12, md: 3 }}>
                        <StatCard {...data[0]} />
                    </Grid> */}
                    <Grid size={{ xs: 12, md: 6 }}>
                        <SessionsChart />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <PageViewsBarChart />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    );
}

export default Panel;
