import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { db, collection, addDoc } from '../firebase.config';
import { auth } from '../firebase.config';  // Import auth for the current user's ID
import { Grid, TextField, Button, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import ActuacionesEstandarizadas from "../data/ActuacionesEstandarizadas";

const ProyectoForm = () => {
    const [sectores, setSectores] = useState([]);
    const [fichas, setFichas] = useState([]);
    const [selectedSector, setSelectedSector] = useState("");
    const [selectedFicha, setSelectedFicha] = useState("");

    const handleSectorChange = (e) => {
        const newSector = e.target.value;
        setSelectedSector(newSector);
        setSelectedFicha(""); // Reset Ficha when sector changes
    };

    const handleFichaChange = (e) => {
        const newFicha = e.target.value;
        setSelectedFicha(newFicha);
    };

    const sortedSectores = useMemo(() => [...new Set(ActuacionesEstandarizadas.map(item => item["SECTOR"]))].sort(), []);

    useEffect(() => {
        setSectores(sortedSectores);
        if (sortedSectores.length > 0) {
            setSelectedSector(sortedSectores[0]);
        }
    }, [sortedSectores]);

    // Memoize fichas for a selected sector
    const getFichas = useCallback(() => {
        return ActuacionesEstandarizadas
            .filter(item => item["SECTOR"] === selectedSector)
            .map(item => item["FICHA"])
            .sort();
    }, [selectedSector]);

    useEffect(() => {
        if (selectedSector) {
            const fichasList = getFichas();
            setFichas(fichasList);
            if (fichasList.length > 0) {
                setSelectedFicha(fichasList[0]);
            }
        } else {
            setFichas([]);
        }
    }, [selectedSector, getFichas]);

    const componentName = selectedFicha.split(':')[0]; // Use selectedFicha for dynamic component



    // const componentName = ficha.split(':')[0]; // Use ficha from proyecto if editing

    const [camposFormulario, setCamposFormulario] = useState([]);
    const [camposCalculados, setCamposCalculados] = useState([]);
    const [camposSelect, setCamposSelect] = useState([]);

    const [formData, setFormData] = useState({});
    const [selectData, setSelectData] = useState({});
    const [calculatedData, setCalculatedData] = useState({});

    useEffect(() => {
        if (componentName) {
            import(`./fichas/${componentName}`)
                .then((module) => {
                    setCamposFormulario(module.camposFormulario || []);
                    setCamposCalculados(module.camposCalculados || []);
                    setCamposSelect(module.camposSelect || []);

                    // Initialize formData and selectData with default values after loading module
                    setFormData(
                        (module.camposFormulario || []).reduce((acc, campo) => {
                            acc[campo.nombre] = campo.valorPorDefecto || '';
                            return acc;
                        }, {})
                    );

                    setSelectData(
                        (module.camposSelect || []).reduce((acc, campo) => {
                            acc[campo.nombre] = campo.opciones[0] || ''; // Set the first option as default
                            return acc;
                        }, {})
                    );
                })
                .catch((err) => console.error('Error al cargar el módulo:', err));
        }
    }, [componentName]);
    // 
    // 
    // 
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setSelectData({
            ...selectData,
            [name]: value,
        });
    };

    useEffect(() => {
        const calculados = { ...calculatedData };
        camposCalculados.forEach((campo) => {
            calculados[campo.nombre] = campo.calculate({
                ...formData,
                ...calculados,
            });
        });
        setCalculatedData(calculados);
    }, [formData, camposCalculados]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = auth.currentUser;  // Get the currently logged-in user
            if (!user) {
                alert("Usuario no autenticado");
                return;
            }

            await addDoc(collection(db, "proyectos"), {
                ...formData,
                ...calculatedData,
                ...selectData,
                userId: user.uid,  // Save the user ID
                ficha: selectedFicha,  // Store the ficha value
            });
            alert("Proyecto guardado con éxito");

            // Reset form data to default values
            setFormData(
                camposFormulario.reduce((acc, campo) => {
                    acc[campo.nombre] = campo.valorPorDefecto || '';
                    return acc;
                }, {})
            );

            // Reset select data to the first option of each select field
            setSelectData(
                camposSelect.reduce((acc, campo) => {
                    acc[campo.nombre] = campo.opciones[0] || '';
                    return acc;
                }, {})
            );

            setCalculatedData({});
        } catch (error) {
            console.error("Error al guardar el proyecto: ", error);
        }
    };

    return (
        <>


            {/* Sector Selector */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Sector</InputLabel>
                <Select value={selectedSector} onChange={handleSectorChange} label="Sector">
                    {sectores.map((sector, index) => (
                        <MenuItem key={index} value={sector}>
                            {sector}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Ficha Selector */}
            <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedSector}>
                <InputLabel>Ficha</InputLabel>
                <Select value={selectedFicha} onChange={handleFichaChange} label="Ficha">
                    {fichas.map((ficha, index) => (
                        <MenuItem key={index} value={ficha}>
                            {ficha}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>



            <form onSubmit={handleSubmit}>
                {/* Campos de texto y numéricos */}
                {camposFormulario.map((campo) => (
                    <TextField
                        key={campo.nombre}
                        label={campo.label}
                        type={campo.type === 'number' ? 'number' : 'text'}
                        name={campo.nombre}
                        value={formData[campo.nombre]}
                        onChange={handleInputChange}
                        placeholder={campo.ayuda || ''}
                        fullWidth
                        margin="normal"
                    />
                ))}

                {/* Campos select */}
                {camposSelect.map((campo) => (
                    <FormControl fullWidth margin="normal" key={campo.nombre}>
                        <InputLabel>{campo.label}</InputLabel>
                        <Select
                            name={campo.nombre}
                            value={selectData[campo.nombre]}
                            onChange={handleSelectChange}
                            label={campo.label}
                        >
                            {campo.opciones.map((opcion, index) => (
                                <MenuItem key={index} value={opcion}>
                                    {opcion}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ))}

                {/* Campos calculados (solo lectura) */}
                {camposCalculados.map((campo) => (
                    <TextField
                        key={campo.nombre}
                        label={campo.label}
                        type="text"
                        name={campo.nombre}
                        value={calculatedData[campo.nombre] || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        margin="normal"
                    />
                ))}

                {/* Botón de envío */}
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Guardar Proyecto
                </Button>
            </form>
        </>
    );
};

export default ProyectoForm;
