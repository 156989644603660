// src/firebase.config.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database'; // Importa la función para la base de datos
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"; // Add this import
import {  collection, addDoc } from "firebase/firestore";


  
const firebaseConfig = {
  apiKey: "AIzaSyDY1GhUR47WGpJcNhpq2gPTOKIyCJ1_oaw",
  authDomain: "prueba-440b0.firebaseapp.com",
  databaseURL: "https://prueba-440b0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "prueba-440b0",
  storageBucket: "prueba-440b0.appspot.com",
  messagingSenderId: "799443941595",
  appId: "1:799443941595:web:6d12fa146ec1308a20f4fe",
  measurementId: "G-W9NBWXRCCF"
};  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// activando sericios
export const db = getFirestore(app)
export const auth = getAuth(app);
export const database = getDatabase(app); 
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export { collection, addDoc };





// configuracion para el house marketplace
// ==========================================
// Firebase Setup For House Marketplace
// Create Firebase Project
// Create web app within firebase to get config values
// Install firebase in your project npm i firebase
// Create a config file in your project
// Add authentication for email/password and Google
// Create a user from Firebase
// Enable Firestore
// Add rules for firestore
// Enable storage
// Add rules for storage
// --------------------------------------------------
// // FIRESTORE RULES


// rules_version = '2';
// service cloud.firestore {
//   match /databases/{database}/documents {
//     // Listings
//     match /listings/{listing} {
//         allow read;
//         allow create: if request.auth != null;
//         allow update: if resource.data.userRef == request.auth.uid;
//         allow delete: if resource.data.userRef == request.auth.uid;
//     }

//     // Users
//     match /users/{user} {
//     	allow read;
//     	allow create;
//     	allow update: if request.auth.uid == user
//     }
//   }
// }




// // STORAGE RULES

// rules_version = '2';
// service firebase.storage {
//   match /b/{bucket}/o {
//     match /{allPaths=**} {
//       allow read;
//       allow write: if
//       request.auth != null &&
//       request.resource.size < 2 * 1024 * 1024 && //2MB
//       request.resource.contentType.matches('image/.*')
//     }
//   }
// }


