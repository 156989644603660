import React, { useState } from "react";
import { Box, Typography, Tab, Tabs, } from "@mui/material";
import AnexoIIEficienciaEnergetica2024 from '../data/AgentesObligados.jsx'; // Adjust the import path accordingly
import AgentesDelegados from '../data/AgentesDelegados.jsx'; // Adjust the import path accordingly
import ListaComponent from '../components/ListaComponent';

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}><Typography>{children}</Typography></Box>}
    </div>
  );
}

const Agentes = () => {

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ padding: 2 }}>
      <main>
        {(true) && (
          <>
            <Box>
              <p style={{ fontSize: "42px", textAlign: "center" }}>
                Los agentes del Sistema
              </p>

              <div>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                  centered
                >
                  <Tab value={0} label="Sujetos Obligados" />
                  <Tab value={1} label="delegados" />
                  <Tab value={2} label="Usuarios finales" />
                  <Tab value={3} label="Verificador" />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <ListaComponent items={[
                    ['- Definidos en la OM de obligaciones que se publica cada año antes del 30 de marzo', 'mkdocsAyuda/site/industrial/IND010.  Mejora del aislamiento de tuberías y superficies planas de instalaciones y equipos utilizados en procesos industriales para temperaturas de más de 60°./index.html'],
                    ['- Las obligaciones de ahorro de cada SO se definen en la misma OM así como la cantidad mínima de aportación económica al FNEE', ''],
                    ['- Se deberá de informar, en la OM de obligaciones del año n, del % máximo de obligaciones que un SO podrá justificar mediante CAE en los años n+1 y n+2.', ''],
                    ['- Un SO deberá tener un mínimo de obligación de ahorro para que pueda solicitar la emisión de CAE.', ''],
                    ['- Compra/venta y Liquidación de CAE.', ''],
                    ['- No habrá mínimo de obligación para que un SO pueda comprar, vender y liquidar CAE.', ''],
                    ['- Un SO podrá liquidar un CAE contra su obligación de ahorro durante los tres años posteriores a la finalización de la actuación que dio lugar al CAE.', ''],
                    ['- La unidad mínima para comprar o vender CAE y para liquidar obligaciones de ahorro será 1 CAE = 1kWh/año.', '']
                  ]} />
                  <p style={{ fontSize: "21px", textAlign: "center" }}>
                    Listado
                  </p>
                  <AnexoIIEficienciaEnergetica2024 />
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <ListaComponent items={[
                    ['• Son los usuarios de la instalación equipo o elemento donde se ha llevado a cabo la actuación de eficiencia energética, y por tanto los que disfrutan de la mejora. Normalmente coinciden con quien lleva a cabo la inversión de la actuación y son, por tanto, los propietarios originales del ahorro de energía.', ''],
                    ['• Mediante el Acuerdo CAE, el ahorro pasa del propietario del ahorro al SO/SD. A cambio debe de haber una contraprestación para el usuario final.', ''],
                    ['• Esta contraprestación no es una subvención ni está sujeta a la reglamentación de ayuda de estado', ''],
                    ['• El propietario original del ahorro deberá de facilitar a los SO/SD la documentación necesaria en relación con la  actuación ejecutada para que el SO/SD pueda justificar que se ha llevado a cabo dicha actuación y los ahorros que ha generado.', '']
                  ]} />

                </TabPanel>

                <TabPanel value={value} index={1}>
                  <ListaComponent items={[
                    ['Acreditados por la DGPEM. Cumplir requisitos de:', ''],
                    ['* Capacidad legal,', ''],
                    ['* Capacidad técnica con equipo con profesionales cualificados y sistema de gestión de calidad certificado por entidad acreditada,', ''],
                    ['* Capacidad económica con un mínimo de fondos propios y volumen de facturación y', ''],
                    ['* Seguro de responsabilidad civil o garantía financiera', ''],
                    [' ', ''],
                    ['Los compromisos de obtención de ahorros mediante CAE de cada SD se definen mediante un contrato de delegación con cada SO, cuando así lo acuerden ambas partes.', ''],
                    ['Podrá celebrar contratos de delegación con varios SO.', ''],
                    ['Compra/venta y liquidación de CAE.', ''],
                    ['- Un SD podrá liquidar un CAE contra la obligación de ahorro de un SO con quien tenga un contrato de delegación en los tres años posteriores a la finalización la actuación que dio lugar al CAE.', ''],
                    ['- La unidad mínima para comprar o vender CAE o liquidar obligaciones de ahorro será 1 CAE = 1kWh/año.', '']
                  ]} />
                  <AgentesDelegados />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <ListaComponent items={[
                    ['ORGANISMO EVALUADOR DE LA CONFORMIDAD', ''],
                    ['• Serán acreditados por ENAC', ''],
                    ['• Existe un protocolo de acreditación distinto según se trate verificador de actuaciones estandarizadas o bien de verificador de actuaciones singulares. En este último caso, la acreditación además se hará por sectores.', ''],
                    ['• Son los responsables de emitir un dictamen sobre los ahorros conseguidos, tanto en actuaciones estandarizadas como en actuaciones singulares.', ''],
                    ['• El dictamen favorable del verificador es preceptivo para la presentación del expediente para la solicitud de emisión de CAE ante el Gestor Autonómico.', ''],
                  ]} />

                </TabPanel>
              </div>
            </Box>
          </>
        )}
      </main>
    </Box>
  );
};
export default Agentes;
