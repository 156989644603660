import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { db } from '../firebase.config';  // Asegúrate de que la ruta sea correcta
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore'; // Importa deleteDoc
import { Alert } from '@mui/material';  // Importa el componente Alert de Material-UI

const ProyectosDataGrid = () => {
  const [proyectos, setProyectos] = useState([]);
  const [loading, setLoading] = useState(true);

  // Función para obtener los proyectos desde Firestore
  useEffect(() => {
    const fetchProyectos = async () => {
      const proyectosCol = collection(db, 'proyectos');
      const proyectosSnapshot = await getDocs(proyectosCol);
      const proyectosList = proyectosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProyectos(proyectosList);
      setLoading(false);
    };

    fetchProyectos();
  }, []);

  // Función para eliminar un documento
  const handleDelete = async (id) => {
    try {
      const proyectoDoc = doc(db, 'proyectos', id);
      await deleteDoc(proyectoDoc);
      // Actualiza el estado después de eliminar el documento
      setProyectos(proyectos.filter((proyecto) => proyecto.id !== id));
    } catch (error) {
      console.error('Error al eliminar el documento:', error);
    }
  };

  // Columnas para el DataGrid, incluida la columna de acciones
  const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'nombre', headerName: 'Nombre', width: 200 },
    { field: 'descripcion', headerName: 'Descripción', width: 300 },
    { field: 'fecha', headerName: 'Fecha', width: 150 },
    // Columna de acciones con el botón de eliminar
    {
      field: 'acciones',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params) => (
        <button
          onClick={() => handleDelete(params.row.id)}
          style={{
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            padding: '5px 10px',
            cursor: 'pointer',
            borderRadius: '5px',
          }}
        >
          Borrar
        </button>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      {/* Mostrar el mensaje de alerta si no hay proyectos */}
      {proyectos.length === 0 ? (
        <Alert severity="info">No hay elementos disponibles.</Alert>
      ) : (
        <DataGrid
          rows={proyectos}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ProyectosDataGrid;

