import React, { useState } from 'react';
import { List, ListItemText, ListItemButton, Dialog, DialogContent } from '@mui/material';

const ListaComponent = ({ items }) => {
  const [open, setOpen] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');

  const handleClickOpen = (url) => {
    if (url !== '') { // Solo abre el modal si la URL no es '#'
      setSelectedUrl(url);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUrl('');
  };

  return (
    <>
      <List dense>
        {items.map(([label, url], index) => (
          <ListItemButton
            key={index}
            sx={{ marginBottom: -2, pl: 4 }}
            onClick={() => handleClickOpen(url)}
          >
            <ListItemText primary={label} />
          </ListItemButton>
        ))}
      </List>

      {/* Modal con iframe */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <iframe
            src={selectedUrl}
            width="100%"
            height="500px"
            title="Contenido"
            style={{ border: 'none' }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ListaComponent;
