import React, { useEffect, useState, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getFirestore, collection, onSnapshot, query, where, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  IconButton, Menu, MenuItem, Divider, Typography, Box, Alert, ListItemIcon, ListItemText
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import SavingsIcon from '@mui/icons-material/Savings';

export default function ConveniosDataGrid({ estadoFiltro, userRefFiltro }) {
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userRole, setUserRole] = useState('');
  const db = getFirestore();
  const auth = getAuth();
  const [oferta, setOferta] = useState('');

  // Obtener el rol del usuario autenticado
  useEffect(() => {
    const fetchUserRole = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      }
    };
    fetchUserRole();
  }, [auth]);

  // Suscripción a convenios con filtros
  const subscribeToConvenios = useCallback(() => {
    let conveniosQuery = collection(db, 'convenios');
    if (estadoFiltro) {
      conveniosQuery = query(conveniosQuery, where('estado', '==', estadoFiltro));
    }

    // Ajustar la consulta según el rol del usuario
    if (userRole === 'cedente' && userRefFiltro) {
      conveniosQuery = query(conveniosQuery, where('userRef', '==', userRefFiltro));
    } else if (userRole === 'delegado' && userRefFiltro) {
      conveniosQuery = query(conveniosQuery, where('comprador', '==', userRefFiltro));
    }

    return onSnapshot(conveniosQuery, (snapshot) => {
      const conveniosData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRows(conveniosData);
    });
  }, [db, estadoFiltro, userRefFiltro, userRole]);

  // Ejecutar suscripción cada vez que cambie la consulta
  useEffect(() => {
    const unsubscribe = subscribeToConvenios();
    return () => unsubscribe();
  }, [subscribeToConvenios]);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
    setOferta(row.oferta);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleAcceptAndDelete = async (idListing, id) => {
    try {
      await handleAccept(idListing, id); // Espera a que se complete handleAccept
      await handleDelete(id); // Luego, espera a que se complete handleDelete
    } catch (error) {
      console.error("Error al aceptar y eliminar:", error);
      // Aquí puedes agregar lógica para manejar errores, como mostrar un mensaje al usuario
    }
  };

  const handleDelete = async (id) => {
    alert(id)
    const convenioDoc = doc(db, 'convenios', id);

    try {
      // Eliminar el documento de la colección 'convenios'
      await deleteDoc(convenioDoc);
      console.log(`Convenio con ID ${id} ha sido eliminado.`);
    } catch (error) {
      console.error("Error deleting document: ", error);
      throw error; // Lanza el error para que handleAcceptAndDelete lo maneje
    }
  };

  const handleAccept00 = async (convenioIdListing, convenioId) => {
    if (userRole !== 'cedente') {
      console.error("Acción no permitida: el usuario no es un cedente.");
      return;
    }

    try {
      const currentDate = new Date(); // Obtener la fecha actual

      // Obtener el documento en la colección 'listings'
      const listingDocRef = doc(db, 'listings', convenioIdListing);
      const listingDoc = await getDoc(listingDocRef); // Obtener el documento

      // Actualizar su estado a 'comprado' y añadir la fecha de aceptación
      await updateDoc(listingDocRef, {
        estado: 'comprado',
        fechaAceptacion: currentDate, // Añadir la fecha de aceptación
        comprador: listingDoc.exists() && listingDoc.data().comprador
          ? listingDoc.data().comprador // Mantener el valor existente si ya existe
          : auth.currentUser.uid, // Establecer el comprador como el ID del usuario autenticado
      });

      // Obtener el documento en la colección 'convenios' y actualizar el campo comprador
      const convenioDocRef = doc(db, 'convenios', convenioId);
      await updateDoc(convenioDocRef, {
        comprador: auth.currentUser.uid, // Establecer el comprador como el ID del usuario autenticado
      });

      console.log(`El estado del listing ${convenioIdListing} se ha actualizado a 'comprado'.`);
    } catch (error) {
      console.error("Error al aceptar el convenio:", error);
      throw error; // Lanza el error para que handleAcceptAndDelete lo maneje
    }
  };


  const handleAccept = async (convenioIdListing, convenioId) => {
    if (userRole !== 'cedente') {
      console.error("Acción no permitida: el usuario no es un cedente.");
      return;
    }
  
    try {
      const currentDate = new Date(); // Obtener la fecha actual
  
      // Obtener el documento en la colección 'convenios' para obtener el comprador
      const convenioDocRef = doc(db, 'convenios', convenioId);
      const convenioDoc = await getDoc(convenioDocRef);
  
      // Verificar si el documento de convenio existe
      if (!convenioDoc.exists()) {
        console.error("El documento de convenio no existe.");
        return;
      }
  
      // Obtener el valor del comprador desde el documento de convenio
      const convenioComprador = convenioDoc.data().comprador;
  
      // Obtener el documento en la colección 'listings'
      const listingDocRef = doc(db, 'listings', convenioIdListing);
      const listingDoc = await getDoc(listingDocRef); // Obtener el documento
  
      // Actualizar su estado a 'comprado' y añadir la fecha de aceptación
      await updateDoc(listingDocRef, {
        estado: 'comprado',
        fechaAceptacion: currentDate, // Añadir la fecha de aceptación
        comprador: convenioComprador || auth.currentUser.uid, // Usar comprador del convenio o el ID del usuario autenticado
      });
  
      console.log(`El estado del listing ${convenioIdListing} se ha actualizado a 'comprado' con el comprador del convenio.`);
    } catch (error) {
      console.error("Error al aceptar el convenio:", error);
      throw error; // Lanza el error para que handleAcceptAndDelete lo maneje
    }
  };
  

  const columns = [
    {
      field: 'actions',
      headerName: '',
      width: 11,
      renderCell: (params) => (
        <>
          <IconButton onClick={(e) => handleMenuOpen(e, params.row)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl && selectedRow?.id === params.row.id)}
            onClose={handleMenuClose}
          >
            <MenuItem disabled={1}>
              <Typography variant="body2">
                <DescriptionIcon fontSize="small" /> {selectedRow?.selectedFicha}
              </Typography>
            </MenuItem>
            <MenuItem disabled={1}>
              <Typography variant="body2">
                <SavingsIcon fontSize="small" /> {selectedRow?.ahorroEnergia} kWh
              </Typography>
            </MenuItem>
            <Divider />
            {userRole === 'cedente' && (
              <MenuItem onClick={() => handleAcceptAndDelete(params.row.idListing, params.row.id)}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  Aceptar
                </ListItemText>
              </MenuItem>
            )}

            {userRole === 'delegado' && (
              <MenuItem onClick={() => handleDelete(params.row.id)}>

                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  Borrar
                </ListItemText>
              </MenuItem>
            )}
            {/* Otros elementos del menú */}
          </Menu>
        </>
      ),
    },
    { field: 'ahorroEnergia', headerName: 'ahorroEnergia', width: 105 },
    { field: 'oferta', headerName: 'oferta', width: 105 },
    {
      field: 'selectedFicha',
      headerName: 'Ficha',
      width: 444,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2em' }}>
          {params.value}
        </div>
      ),
    },
    { field: 'selectedComunidad', headerName: 'selectedComunidad', width: 111 },
    { field: 'estado', headerName: 'Estado', width: 111 },
    { field: 'idListing', headerName: 'idListing', width: 77 },
    { field: 'userRefPropietario', headerName: 'userRefPropietario', width: 77 },
    { field: 'comprador', headerName: 'comprador', width: 77 },
  ];

  return (
    <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto' }}>
      {rows.length === 0 ? (
        <Box textAlign="center" p={2}>
          <Alert severity="info">No hay elementos disponibles.</Alert>

        </Box>
      ) : (
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pageSizeOptions={[10, 20, 50]}
          disableColumnResize
          density="compact"
        />
      )}
    </div>
  );
}

