import React, { useState, useEffect, useRef } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, storage } from '../firebase.config'; // Add storage import
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Add storage functions
// import { Box, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Button, List, ListItem, ListItemText, Link } from "@mui/material";
import { Modal, Box, Typography, FormControl, Input, InputLabel, Select, MenuItem, TextField, Button, List, ListItem, ListItemText, Link, IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";

import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import ActuacionesEstandarizadas from "../data/ActuacionesEstandarizadas";
import Comunidades from "../data/Comunidades";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

const CreateListing = () => {
    const [loading, setLoading] = useState(false);
    const [sectores, setSectores] = useState([]);
    const [fichas, setFichas] = useState([]);
    const [selectedSector, setSelectedSector] = useState("");
    const [selectedFicha, setSelectedFicha] = useState("");
    const [selectedComunidad, setSelectedComunidad] = useState(Comunidades[0].comunidad);
    const [fechaRealizacion, setFechaRealizacion] = useState(new Date().toISOString().split("T")[0]);
    const [zipFile, setZipFile] = useState(null);
    const [formData, setFormData] = useState({
        ahorroEnergia: 220,
        contraprestacion: 100,
        estado: 'ofertado',
        userRef: '',
        useremail: '',
    });
    const auth = getAuth();
    const navigate = useNavigate();
    const isMounted = useRef(true);

    const [openHelpModal, setOpenHelpModal] = useState(false);



    const handleHelpOpen = () => setOpenHelpModal(true);
    const handleHelpClose = () => setOpenHelpModal(false);

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const handleClick1 = () => {
        setOpen1(!open1);
    };
    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (isMounted.current) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setFormData((prevData) => ({ ...prevData, userRef: user.uid, useremail: user.email }));
                } else {
                    navigate('sign-in');
                }
            });
        }
        return () => { isMounted.current = false; };
    }, [auth, navigate]);

    useEffect(() => {
        const uniqueSectors = [...new Set(ActuacionesEstandarizadas.map(item => item["SECTOR"]))];
        setSectores(uniqueSectors);
        if (uniqueSectors.length > 0) {
            setSelectedSector(uniqueSectors[0]);
        }
    }, []);

    useEffect(() => {
        if (selectedSector) {
            const filteredElements = ActuacionesEstandarizadas
                .filter(item => item["SECTOR"] === selectedSector)
                .map(item => item["FICHA"]);
            setFichas(filteredElements);
            if (filteredElements.length > 0) {
                setSelectedFicha(filteredElements[0]);
            }
        } else {
            setFichas([]);
        }
    }, [selectedSector]);


    useEffect(() => {
        if (selectedFicha) {
            const fichaParts = selectedFicha.split(":");
            setFormData(prevState => ({
                ...prevState
            }));
            setFormData({ ...formData, selectedSector, selectedFicha, selectedComunidad, fechaRealizacion });
        }
    }, [selectedFicha, selectedComunidad, fechaRealizacion]);



    const handleSectorChange = (e) => {
        const newSector = e.target.value;
        setSelectedSector(newSector);
        setSelectedFicha("");
        setFormData({ ...formData, selectedSector: newSector, selectedFicha: "", selectedComunidad, fechaRealizacion });
    };

    const handleFichaChange = (e) => {
        const newFicha = e.target.value;
        setSelectedFicha(newFicha);
        setFormData({ ...formData, selectedFicha: newFicha, selectedComunidad, fechaRealizacion });
    };

    const handleComunidadChange = (e) => {
        const newComunidad = e.target.value;
        setSelectedComunidad(newComunidad);
        setFormData({ ...formData, selectedComunidad: newComunidad, fechaRealizacion });
    };

    const handleFechaChange = (e) => {
        const newFecha = e.target.value;
        setFechaRealizacion(newFecha);
        setFormData({ ...formData, fechaRealizacion: newFecha, selectedComunidad });
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['application/zip', 'application/x-rar-compressed'];
        if (file && allowedTypes.includes(file.type)) {
            setZipFile(file);
        } else {
            toast.error("Please upload a valid zip or rar file.");
        }
    };

    const uploadZipFile = async () => {
        if (!zipFile) return null;

        const zipFileRef = ref(storage, `uploads/${zipFile.name}`);
        await uploadBytes(zipFileRef, zipFile);
        return await getDownloadURL(zipFileRef);
    };

    const ahorroTotal = formData.ahorroEnergia * formData.contraprestacion;

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const zipFileUrl = await uploadZipFile();

        const formDataCopy = {
            ...formData,
            zipFileUrl, // Include the file URL in Firestore document
            timestamp: serverTimestamp(),
        };

        try {
            await addDoc(collection(db, 'listings'), formDataCopy);
            setLoading(false);
            toast.success('Listing saved');
            navigate(`/user/profile`);
        } catch (error) {
            setLoading(false);
            toast.error('Failed to create listing');
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
                Crear Oferta
            </Typography>
            <form onSubmit={onSubmit}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Sector</InputLabel>
                    <Select value={selectedSector} onChange={handleSectorChange} label="Sector">
                        {sectores.sort().map((sector, index) => (
                            <MenuItem key={index} value={sector}>
                                {sector}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedSector}>
                    <InputLabel>Ficha</InputLabel>
                    <Select value={selectedFicha} onChange={handleFichaChange} label="Ficha">
                        {fichas.sort().map((ficha, index) => (
                            <MenuItem key={index} value={ficha}>
                                {ficha}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Comunidad Autónoma</InputLabel>
                    <Select value={selectedComunidad} onChange={handleComunidadChange} label="Comunidad Autónoma">
                        {Comunidades.map(({ comunidad }, index) => (
                            <MenuItem key={index} value={comunidad}>
                                {comunidad}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    label="Fecha de realización"
                    type="date"
                    id="fechaRealizacion"
                    value={fechaRealizacion}
                    onChange={handleFechaChange}
                    required
                    sx={{ mb: 2 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    fullWidth
                    label="Ahorro de energía (kWh)"
                    type="number"
                    id="ahorroEnergia"
                    value={formData.ahorroEnergia}
                    onChange={handleInputChange}
                    min="0"
                    required
                    sx={{ mb: 2 }}
                />

                <TextField
                    fullWidth
                    label="Contraprestación"
                    type="number"
                    id="contraprestacion"
                    value={formData.contraprestacion}
                    onChange={handleInputChange}
                    min="0"
                    required
                    sx={{ mb: 2 }}
                />


                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <IconButton color="primary" onClick={handleHelpOpen}>
                        <HelpOutlineIcon />
                    </IconButton>
                    <Input
                        type="file"
                        accept=".zip,.rar,.z01,.z10"
                        onChange={handleFileChange}
                        required
                        style={{
                            display: 'block',
                            width: '100%',          // Full width
                            padding: '8px 16px',
                            border: '1px solid lightgrey', // Primary color border
                            borderRadius: '4px',
                            // backgroundColor: 'white', // Primary background
                            // color: '#fff',
                            // cursor: 'pointer',
                            // fontSize: '16px',
                        }}
                    />

                </Box>
                <Typography variant="body1">
                    Ahorro total: {ahorroTotal} €
                </Typography>

                <Dialog open={openHelpModal} onClose={handleHelpClose} maxWidth="sm" fullWidth>
                    <DialogTitle>Archivo comprimido  'Actuacion.zip' , que incluya los documentos segun se trate de:</DialogTitle>
                    <DialogContent dividers>


                        <List
                            sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"

                        >
                            <ListItemButton onClick={handleClick1}>
                                <ListItemIcon>
                                    <ListAltOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Actuación estandarizada" />
                                {open1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open1} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/* <ListItemButton sx={{ marginBottom: -2, pl: 4 }}
                                        onClick={() => window.open("https://www.miteco.gob.es/es/energia/eficiencia/cae/catalogo-de-fichas/catalogo-vigente-de-fichas.html", "_blank")}
                                    >
                                        <ListItemText primary="1- Ficha cumplimentada y firmada por el representante legal del solicitante de la emisión de CAE." />
                                    </ListItemButton> */}

                                    <ListItemButton
                                        sx={{ marginBottom: -2, pl: 4 }}
                                        onClick={() => {
                                            // Extraer la parte antes de los dos puntos
                                            const fichaSinDosPuntos = selectedFicha.split(':')[0];

                                            const url = `https://www.miteco.gob.es/content/dam/miteco/es/energia/files-1/Eficiencia/CAE/catálogo-vigente/fichas-autocompletables/${selectedSector.toLowerCase()}/Ficha%20${fichaSinDosPuntos}.pdf`;
                                            // alert(url)
                                            window.open(url, "_blank");
                                        }}
                                    >
                                        <ListItemText primary="1- Ficha cumplimentada y firmada por el representante legal del solicitante de la emisión de CAE." />
                                    </ListItemButton>

                                    <ListItemButton sx={{ marginBottom: -2, pl: 4 }}
                                        onClick={() => window.open("https://www.miteco.gob.es/content/dam/miteco/es/energia/files-1/Eficiencia/CAE/cat%C3%A1logo-vigente/Anexo%20I%20de%20las%20fichas_DR%20subvenciones.pdf", "_blank")}
                                    >
                                        <ListItemText primary="2-  Declaración responsable formalizada por el propietario inicial del ahorro de energía final referida a la solicitud y/u obtención de ayudas públicas para la misma actuación de ahorro de energía." />
                                    </ListItemButton>
                                    <ListItemButton sx={{ marginBottom: -2, pl: 4 }}
                                        onClick={() => window.open("/site/index.html", "_blank")}
                                    >                                        <ListItemText primary="3- Facturas justificativas de la inversión realizada, que incluyan una descripción detallada de los elementos principales. " />
                                    </ListItemButton>
                                    <ListItemButton sx={{ marginBottom: -2, pl: 4 }}>
                                        <ListItemText primary="4- Informe fotográfico antes y después de la actuación." />
                                    </ListItemButton>
                                    <ListItemButton sx={{ marginBottom: -2, pl: 4 }}>
                                        <ListItemText primary=" - Resto de documentos incluidos en el apartado 'DOCUMENTACIÓN PARA JUSTIFICAR LOS AHORROS DE LA ACTUACIÓN Y SU REALIZACIÓN'." />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        </List>

                        <List
                            sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"

                        >
                            <ListItemButton onClick={handleClick}>
                                <ListItemIcon>
                                    <EngineeringOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Actuación singular:" />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ marginBottom: -2, pl: 4 }}>
                                        <ListItemText primary="S3-1- Memoria o proyecto técnico de la actuación realizada, con sus correspondientes planos y anexos" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ marginBottom: -2, pl: 4 }}>
                                        <ListItemText primary="S3-2- En su caso, certificado final de obra y de la instalación" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ marginBottom: -2, pl: 4 }}>
                                        <ListItemText primary="S3-3- Informe fotográfico antes y después de la actuación." />
                                    </ListItemButton>
                                    <ListItemButton sx={{ marginBottom: -2, pl: 4 }}>
                                        <ListItemText primary="S3-4- Documentación que permita confirmar el ahorro de energía resultante de la ejecución de la actuación, especificando el consumo de energía final antes de acometer la actuación y el consumo de energía final una vez llevada a cabo" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        </List>
                    </DialogContent>
                </Dialog>

                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 2 }}
                >
                    Ofertar
                </Button>
            </form>
        </Box>
    );
};

export default CreateListing;
