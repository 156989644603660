import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase.config';

const VerUsuario = () => {
    const [userRef, setUserRef] = useState(null); // Estado para el usuario autenticado
    const [userrole, setRole] = useState(''); // Estado para el rol del usuario

    useEffect(() => {
        const unsubscribeUser = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUserRef(currentUser); // Usuario logeado
                
                // Obtener el rol desde Firestore
                const userDocRef = doc(db, 'users', currentUser.uid);
                const userDoc = await getDoc(userDocRef);
                
                if (userDoc.exists()) {
                    setRole(userDoc.data().role || 'Sin rol'); // Asigna el rol o 'Sin rol' si no existe
                } else {
                    setRole('Sin rol');
                }
            } else {
                setUserRef(null); // Usuario no logeado
                setRole('');
            }
        });

        // Limpia la suscripción cuando el componente se desmonte
        return () => unsubscribeUser();
    }, []);

    return (
        <div>
            {userRef ? (
                <p>Usuario logeado: {userRef.email}, ID: {userRef.uid}, Rol: {userrole}</p>
            ) : (
                <p>No hay usuario logeado.</p>
            )}
        </div>
    );
};

export default VerUsuario;
