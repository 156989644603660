// DataGridComponent.js
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import datos from './AgentesDelegados.json';

const AgentesDelegados = () => {
    // Definir las columnas del DataGrid
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'NIF', headerName: 'NIF', width: 150 },
        // { field: 'CodigoIdentificacion', headerName: 'Código de Identificación', width: 200 },
        { field: 'Direccion', headerName: 'Dirección', width: 300 },
        { field: 'Telefono', headerName: 'Teléfono', width: 150 },
        { field: 'Email', headerName: 'Email', width: 200 },
    ];

    // Transformar los datos para incluir un campo `id` único (necesario para el DataGrid)
    const rows = datos.map((item, index) => ({
        id: index + 1, // Genera un ID único basado en el índice
        NIF: item.NIF,
        CodigoIdentificacion: item["Código de identificación"],
        Direccion: item.Dirección,
        Telefono: item.Teléfono,
        Email: item.Email,
    }));

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
            />
        </div>
    );
};

export default AgentesDelegados;
