import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { db } from "../firebase.config";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  getDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Box, Button, Typography, CircularProgress, Tab, Tabs ,ListItemText} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/Forward";
import ListingsDataGrid from '../panel/ListingsDataGrid';
import ConveniosDataGrid from '../panel/ConveniosDataGrid';
import AgentesObligados from '../data/AgentesObligados.jsx'; // Adjust the import path accordingly
import CreateListing from './CreateListing';
import Grid from '@mui/material/Grid2';


function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}><Typography>{children}</Typography></Box>}
    </div>
  );
}

const Profile = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState(null);
  const [role, setRole] = useState("");

  const [formData] = useState({
    name: auth.currentUser?.displayName,
    email: auth.currentUser?.email,
  });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // Fetch user role and listings
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setRole(userSnap.data().role);
        } else {
          toast.error("User document does not exist");
        }

        const listingsRef = collection(db, "listings");
        const q = query(
          listingsRef,
          where("userRef", "==", auth.currentUser.uid),
          orderBy("timestamp", "desc")
        );
        const querySnap = await getDocs(q);
        let listingsArray = [];

        querySnap.forEach((doc) => {
          listingsArray.push({ id: doc.id, data: doc.data() });
        });
        setListings(listingsArray);
      } catch (error) {
        toast.error("Error loading data");
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [auth.currentUser.uid]);

  const onLogout = () => {
    auth.signOut();
    toast.success("Logged out successfully.");
    navigate("/", { replace: true });
  };

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ padding: 2 }}>


      <main>
        {role === "cedente" && (
          <>

            <p style={{ fontSize: "42px", textAlign: "center" }}>
            Mercado de Ahorros Energéticos
            </p>
            <div>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                centered
              >
                <Tab value={0} label="Nuevo" />
                <Tab value={1} label="Disponible" />
                <Tab value={2} label="OFertado" />
                <Tab value={3} label="Vendido" />
              </Tabs>

              <TabPanel value={value} index={0}>

              <Grid container spacing={5}>
                        <Grid size={{ xs: 12, md: 12 }}>
                            <ListItemText>
                                <p style={{ fontSize: "14px", textAlign: "center" }}>
                                    Bienvenido a su área de usuario de la Herramienta de Mercado de Certificados de Ahorro de Energía de MeCAE. Desde este espacio podrás crear un nuevo proyecto, o volver a abrir un proyecto previamente introducido para, si es necesario, modificarlo, completarlo, consultarlo o incluso acceder a determinadas funcionalidades (por ejemplo, imprimir o descargar resúmenes).
                                </p>
                            </ListItemText>
                        </Grid>
                    </Grid>
                <CreateListing />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ListingsDataGrid estadoFiltro="ofertado" userRefFiltro={auth.currentUser.uid} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ConveniosDataGrid estadoFiltro="ofertado" userRefFiltro={auth.currentUser.uid} />
              </TabPanel>

              <TabPanel value={value} index={3}>
                <ListingsDataGrid estadoFiltro="comprado" userRefFiltro={auth.currentUser.uid} />
              </TabPanel>
            </div>


          </>
        )}

        {(role === "delegado" || role === "obligado") && (
          <>
            <Typography variant="h4" gutterBottom>
              Ahorros Energéticos
            </Typography>

            <Box>

              <div>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value={0} label="disponibles" />
                  <Tab value={1} label="ofertados" />
                  <Tab value={2} label="comprados" />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <ListingsDataGrid estadoFiltro="ofertado" />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ConveniosDataGrid estadoFiltro="ofertado" />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ListingsDataGrid estadoFiltro="comprado" />
                </TabPanel>

              </div>
            </Box>
          </>
        )}
      </main>
    </Box>
  );
};

export default Profile;
