import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute';
import Redirect from './components/Redirect';
import Navbar from './components/NavbarComponent';
import Panel from './pages/Panel';
import Explore from './pages/Explore';
import Offers from './pages/Offers';
import Category from './pages/Category';
import Profile from './pages/Profile';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import CreateListing from './pages/CreateListing';
// import EditListing from './pages/EditListing';
// import Listing from './pages/Listing';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Footer from './components/Footer';
import CalculaAhorro from './pages/CalculaAhorro';

import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from './components/CookieConsent'; // Asegúrate de que la ruta sea correcta
import Documentation from './components/Documentation'; // Adjust the import path accordingly
import Blog from './components/Blog'; // Adjust the import path accordingly
import ListingsDataGrid from './panel/ListingsDataGrid'; // Adjust the import path accordingly
// desarrollo
// purebas
// purebas
import GenerarDocumentos from './pp/GenerarDocumentos'; // Ajusta la ruta según tu estructura
import VerUsuario from './pp/VerUsuario.jsx'; // Adjust the import path accordingly
import EnviaCorreo from './pp/EnviaCorreo.jsx'; // Adjust the import path accordingly
import CopyListings from './pp/CopyListings.jsx'; // Adjust the import path accordingly
import Agentes from './pages/Agentes.jsx'; // Adjust the import path accordingly


import ConveniosDataGrid from './panel/ConveniosDataGrid';
import Anexo_II_Eficiencia_Energetica_2024 from './data/AgentesObligados'; // Adjust the import path accordingly
import { purple, red } from '@mui/material/colors';



function App() {
    return (
        <>
            {/* <CookieConsent> */}
            <Router>
                <Navbar />
                <Routes>
                <Route path="/calculaahorro" element={<CalculaAhorro />} />
                <Route path="/documentation" element={<Documentation />} />
                <Route path="/blog" element={<Blog />} />
                    <Route path='/MeCAE' element={<Explore />} />
                    <Route path='/' element={<Panel />} />
                    <Route path='/offers' element={<Offers />} />
                    <Route path='/category/:categoryName' element={<Category />} />
                    <Route path='/user' element={<PrivateRoute redirectTo="/sign-in" />}>
                        <Route path='profile' element={<Profile />} />
                        <Route path='create-listing' element={<CreateListing />} />
                        {/* <Route path='edit-listing/:listingId' element={<EditListing />} /> */}
                    </Route>
                    <Route path='/user/*' element={<Redirect url="/" />} />
                    <Route path='/login' element={<SignIn />} />
                    <Route path='/sign-up' element={<SignUp />} />
                    <Route path='/forgot-password' element={<ForgotPassword />} />
                    {/* <Route path='/category/:categoryName/:listingId' element={<Listing />} /> */}
                    <Route path='/contact/:landlordId' element={<Contact />} />
                    <Route path='/*' element={<NotFound />} />
                    {/*  */}
                    <Route path="/generardocumentos" element={<GenerarDocumentos />} />
                    <Route path="/verusuario" element={<VerUsuario />} />
                    <Route path="/enviacorreo" element={<EnviaCorreo />} />
                    <Route path="/CopyListings" element={<CopyListings />} />
                    {/*  */}
                    <Route path="/Anexo_II_Eficiencia_Energetica_2024" element={<Anexo_II_Eficiencia_Energetica_2024 />} />
                    <Route path="/ConveniosDataGrid" element={<ConveniosDataGrid />} />
                    <Route path="/ListingsDataGrid" element={<ListingsDataGrid />} />
                    <Route path="/Agentes" element={<Agentes />} />
                </Routes>
                <Footer />
            </Router>
            {/* <ToastContainer autoClose={3000} /> */}
            <CookieConsent />
        </>
    );
}

export default App;
