import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined'; import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, analytics } from '../firebase.config';
import { signOut } from 'firebase/auth';
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Importa el icono de Logout
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; // Icono para Ayuda
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import BookIcon from '@mui/icons-material/Book';
import { logEvent } from 'firebase/analytics';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SavingsIcon from '@mui/icons-material/Savings';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
  },
});


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {

    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    logEvent(analytics, 'nombre_evento', { parametro: 'valor' });

    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogout = async () => {
    try {
      await signOut(auth);
      toast.success("Logged out successfully.");
      navigate("/", { replace: true });
    } catch (error) {
      toast.error("Failed to log out.");
    }
  };

  return (

    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <HomeIcon sx={{ color: 'white', position: 'absolute', zIndex: 1 }} />
            <SettingsIcon sx={{ color: 'white', zIndex: 0 }} />
          </Box> */}
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            MeCAE
          </Typography>
          {/* menu hamburguesa para moviles */}
          {/* menu hamburguesa para moviles */}
          {/* menu hamburguesa para moviles */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {user ? (
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button
                    component={Link}
                    to="/user/profile" // Link to the new Documentation route
                  >
                    <CalculateOutlinedIcon /> Mercado
                  </Button>
                </MenuItem>
              ) : (<></>)}
              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  component={Link}
                  to="/calculaahorro" // Link to the new Documentation route
                >
                  <CalculateOutlinedIcon /> Calculadora
                </Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  component={Link}
                  to="/blog" // Link to the new Documentation route
                >
                  <BookIcon />
                  Actualidad
                </Button>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  component={Link}
                  to="/documentation" // Link to the new Documentation route
                >
                  <QuestionMarkIcon /> Ayuda
                </Button>
              </MenuItem>
            </Menu>
          </Box>

          {/* menu para desktop */}
          {/* menu para desktop */}
          {/* menu para desktop */}
          {/* menu para desktop */}
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            MeCAE
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {user ? (<></>) : (<></>)}

            {user ? (
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
                component={Link}
                to="/user/profile" // Link to the new Documentation route
              >
                <EnergySavingsLeafOutlinedIcon />Mercado
              </Button>
            ) : (<></>)}

            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
              component={Link}
              to="/calculaahorro" // Link to the new Documentation route
            >
              <CalculateOutlinedIcon /> Calculadora
            </Button>


            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
              component={Link}
              to="/Agentes" // Link to the new Documentation route
            >
              <PeopleAltOutlinedIcon /> Agentes
            </Button>

            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
              component={Link}
              to="/blog" // Link to the new Documentation route
            >
              <BookIcon />
              Actualidad
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
              component={Link}
              to="/documentation" // Link to the new Documentation route
            >
              <QuestionMarkIcon /> Ayuda
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {user ? (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user.displayName || "User"} src={user.photoURL || "/static/images/avatar/2.jpg"} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography sx={{ display: 'inline', mr: 2 }}>
                      Hola,
                      <br />
                      <strong>{user.email || "Usuario"}</strong> {/* Saludo personalizado con email en negrita */}
                    </Typography>
                  </MenuItem>

                  <MenuItem onClick={handleCloseUserMenu} sx={{ justifyContent: 'center' }}>
                    <Button
                      component={Link}
                      to="/user/profile"
                      startIcon={<PersonOutlineIcon color="inherit" />}
                      sx={{ color: 'inherit', textAlign: 'center', width: '100%' }} // Estilo uniforme
                    >
                      Mi área agente
                    </Button>
                  </MenuItem>

                  <MenuItem onClick={onLogout} sx={{ justifyContent: 'center' }}>
                    <Button
                      startIcon={<ExitToAppIcon color="inherit" />}
                      sx={{ color: 'inherit', textAlign: 'center', width: '100%' }} // Estilo uniforme
                    >
                      Cerrar sesión
                    </Button>
                  </MenuItem>

                  {/* solo para desarrollo */}
                  {/* solo para desarrollo */}
                  {/* solo para desarrollo */}
                  {/* solo para desarrollo */}


                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'black', display: 'block' }}
                    component={Link}
                    to="/enviacorreo" // Link to the new Documentation route
                  >
                    <HelpOutlineIcon /> pp enviacorreo
                  </Button>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'black', display: 'block' }}
                    component={Link}
                    to="/GenerarDocumentos" // Link to the new Documentation route
                  >
                    <HelpOutlineIcon /> pp GenerarDocumentos
                  </Button>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'black', display: 'block' }}
                    component={Link}
                    to="/CopyListings" // Link to the new Documentation route
                  >
                    <HelpOutlineIcon /> pp CopyListings
                  </Button>

                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'black', display: 'block' }}
                    component={Link}
                    to="/VerUsuario " // Link to the new Documentation route
                  >
                    <HelpOutlineIcon /> pp VerUsuario
                  </Button>



                  {/* solo para desarrollo */}
                  {/* solo para desarrollo */}
                  {/* solo para desarrollo */}
                  {/* solo para desarrollo */}


                </Menu>
              </>
            ) : (
              <IconButton component={Link} to="/login" color="inherit">
                <PersonOutlineIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
