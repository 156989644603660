import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../firebase.config';

export default function HighlightedCard() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [user] = useAuthState(auth);

  const navigate = useNavigate();



  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <InsightsRoundedIcon />
        <Typography
          component="h2"
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: '600' }}
        >
          Vende tus ahorros energéticos
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: '8px' }}>
          MeCAE actua en el mercado primario facilitando el contacto entre los propietarios de los ahorros energéticos y los sujetos obligados o delegados.


        </Typography>
        {user ? (<Button
          onClick={() => navigate('/user/profile')}
          variant="contained"
          // size="small"
          color="primary"
        // endIcon={<ChevronRightRoundedIcon />}
        // fullWidth={isSmallScreen}
        >
          Acceso al Mercado
        </Button>) : (<Button
          onClick={() => navigate('/login')}
          variant="contained"
          // size="small"
          color="primary"
        // endIcon={<ChevronRightRoundedIcon />}
        // fullWidth={isSmallScreen}
        >
          Acceso al Mercado
        </Button>)}
      </CardContent>
    </Card>
  );
}
