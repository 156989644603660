import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { db } from '../firebase.config';

const StyledText = styled('text', {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme }) => ({
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fill: (theme.vars || theme).palette.text.secondary,
  variants: [
    { props: { variant: 'primary' }, style: { fontSize: theme.typography.h5.fontSize } },
    { props: { variant: 'secondary' }, style: { fontSize: theme.typography.body2.fontSize } },
  ],
}));

function PieCenterLabel({ primaryText, secondaryText }) {
  const { width, height, left, top } = useDrawingArea();
  const primaryY = top + height / 2 - 10;
  const secondaryY = primaryY + 24;

  return (
    <>
      <StyledText variant="primary" x={left + width / 2} y={primaryY}>
        {primaryText}
      </StyledText>
      <StyledText variant="secondary" x={left + width / 2} y={secondaryY}>
        {secondaryText}
      </StyledText>
    </>
  );
}

export default function TwoLevelPieChart() {
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'listings'));
        const provinciasCount = {};
        const groupedCount = {};

        querySnapshot.forEach((doc) => {
          const { selectedFicha } = doc.data();
          const codigoCompleto = selectedFicha.split(':')[0];
          const codigoTresLetras = codigoCompleto.slice(0, 3);

          provinciasCount[codigoCompleto] = (provinciasCount[codigoCompleto] || 0) + 1;
          groupedCount[codigoTresLetras] = (groupedCount[codigoTresLetras] || 0) + 1;
        });

        const chartData = Object.entries(provinciasCount).map(([codigoCompleto, count]) => ({
          label: codigoCompleto,
          value: count,
        }));
        const groupedChartData = Object.entries(groupedCount).map(([codigoTresLetras, count]) => ({
          label: codigoTresLetras,
          value: count,
        }));

        setData(chartData);
        setGroupedData(groupedChartData);
      } catch (error) {
        console.error('Error al obtener documentos: ', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2">
          Clasificación de Individuos
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PieChart
            series={[
              {
                data: groupedData,
                innerRadius: 50,
                outerRadius: 70,
                highlightScope: { faded: 'global', highlighted: 'item' },
                colors: ['#ff7043', '#ffa726', '#ffb74d'], // Colores para los tres primeros caracteres
              },
              {
                data: data,
                innerRadius: 75,
                outerRadius: 100,
                highlightScope: { faded: 'global', highlighted: 'item' },
                colors: ['#42a5f5', '#64b5f6', '#90caf9'], // Colores para los códigos completos
              },
            ]}
            height={260}
            width={760}
            legend={{
              layout: 'vertical',
              align: 'center',
              justifyContent: 'space-between',
              sx: {
                '.MuiLegend-item:nth-of-type(1) .MuiLegend-itemLabel': {
                  color: '#ff7043', // Color para la primera serie
                },
                '.MuiLegend-item:nth-of-type(2) .MuiLegend-itemLabel': {
                  color: '#42a5f5', // Color para la segunda serie
                },
              },
            }}
          >
            <PieCenterLabel primaryText={`${data.reduce((acc, { value }) => acc + value, 0)} Total`} secondaryText="Individuos" />
          </PieChart>
        </Box>
      </CardContent>
    </Card>
  );
}
