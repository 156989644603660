import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  getFirestore, collection, getDocs, getDoc, addDoc, doc, deleteDoc, onSnapshot, query, where,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  TextField, Button, IconButton, Menu, MenuItem, Divider, Typography, Box,CircularProgress, Alert, Slider,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import DescriptionIcon from '@mui/icons-material/Description';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorage, ref, deleteObject } from 'firebase/storage';


export default function ListingsDataGrid({ estadoFiltro, userRefFiltro }) {
  const [rows, setRows] = useState([]);
  const [offerValue, setOfferValue] = useState(100);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [ofertados, setOfertados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const db = getFirestore();
  const auth = getAuth();

  const fetchUserData = useCallback(async (currentUser) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        setUserRole(userDoc.data().role || 'Sin rol');
      } else {
        setError("El documento del usuario no existe.");
      }

      const conveniosSnapshot = await getDocs(query(
        collection(db, 'convenios'),
        where("comprador", "==", currentUser.uid)
      ));
      setOfertados(conveniosSnapshot.docs.map(doc => doc.data().idListing));
    } catch (err) {
      setError("Error loading user data: " + err.message);
    }
  }, [db]);

  useEffect(() => {
    const unsubscribeUser = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);
      setError(null);
      if (currentUser) {
        await fetchUserData(currentUser);
      } else {
        setUserRole('');
        setOfertados([]);
      }
      setLoading(false);
    });
    return unsubscribeUser;
  }, [auth, fetchUserData]);

  useEffect(() => {
    const unsubscribeListings = onSnapshot(collection(db, 'listings'), (snapshot) => {
      const listingsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).filter((listing) => {
        const matchesEstado = estadoFiltro ? listing.estado === estadoFiltro : true;
        const matchesUserRef = userRefFiltro ? listing.userRef === userRefFiltro : true;
        const notOffered = userRole === 'delegado' ? !ofertados.includes(listing.id) : true;
        return matchesEstado && matchesUserRef && notOffered;
      });
      setRows(listingsData);
      setLoading(false);
    });
    return unsubscribeListings;
  }, [db, estadoFiltro, userRefFiltro, userRole, ofertados]);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
    setOfferValue(row.contraprestacion || 100);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    setOfferValue(100);
  };

  const handleOfferChange = (event, newValue) => {
    const updatedOffer = newValue ?? event.target.value;
    setOfferValue(updatedOffer !== '' && !isNaN(updatedOffer) && updatedOffer >= 0 ? parseFloat(updatedOffer) : '');
  };

  const handleOfferSubmit00 = async (id) => {
    if (!auth.currentUser) {
      setError("Usuario no autenticado.");
      return;
    }

    try {
      const convenioData = {
        idListing: id,
        oferta: parseFloat(offerValue),
        comprador: auth.currentUser.uid,
        producto: selectedRow.ahorroEnergia * offerValue,
        ...selectedRow,
      };
      await addDoc(collection(db, 'convenios'), convenioData);
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      setOfertados((prevOfertados) => [...prevOfertados, id]);
      handleMenuClose();
    } catch (err) {
      setError("Error submitting offer.");
    }
  };

  const handleOfferSubmit = async (id) => {
    if (!auth.currentUser) {
      setError("Usuario no autenticado.");
      return;
    }
  
    try {
      // Crear una copia de selectedRow excluyendo el campo 'id'
      const { id: _, ...selectedRowData } = selectedRow;
      
      const convenioData = {
        idListing: id,
        oferta: parseFloat(offerValue),
        comprador: auth.currentUser.uid,
        producto: selectedRowData.ahorroEnergia * offerValue,
        ...selectedRowData, // Copiar los datos de selectedRow sin el campo 'id'
      };
  
      await addDoc(collection(db, 'convenios'), convenioData);
  
      // Actualizar el estado local para eliminar la fila ofrecida
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      setOfertados((prevOfertados) => [...prevOfertados, id]);
      handleMenuClose();
    } catch (err) {
      setError("Error submitting offer: " + err.message);
    }
  };


  const handleDelete0 = async (id) => {
    try {
      // Buscar documentos en la colección "convenios" que tengan el mismo idListing
      const conveniosQuery = query(
        collection(db, 'convenios'),
        where('idListing', '==', id)
      );
      const conveniosSnapshot = await getDocs(conveniosQuery);
  
      // Borrar cada documento en convenios que coincida con idListing
      const deletePromises = conveniosSnapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletePromises);
  
      // Borrar el documento en "listings"
      await deleteDoc(doc(db, 'listings', id));
  
      // Actualizar el estado local eliminando el documento borrado de la lista de rows
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      handleMenuClose();
    } catch (err) {
      setError("Error deleting document: " + err.message);
    }
  };


  const handleDelete = async (id) => {
    try {
      // Find the document in Firestore and check if the URL exists
      const listingDoc = await getDoc(doc(db, 'listings', id));
      const zipFileUrl = listingDoc.data()?.zipFileUrl;
      if (!zipFileUrl) {
        setError("No file URL found for this listing.");
        return;
      }
  
      // Delete the associated file in Firebase Storage
      const storage = getStorage();
      const fileRef = ref(storage, zipFileUrl);
      await deleteObject(fileRef);
  
      // Delete related documents in the "convenios" collection
      const conveniosQuery = query(
        collection(db, 'convenios'),
        where('idListing', '==', id)
      );
      const conveniosSnapshot = await getDocs(conveniosQuery);
  
      // Delete each matching document in "convenios"
      const deletePromises = conveniosSnapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletePromises);
  
      // Delete the document in the "listings" collection
      await deleteDoc(doc(db, 'listings', id));
  
      // Update the local state by removing the deleted row
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      handleMenuClose();
    } catch (err) {
      setError("Error deleting document and file: " + err.message);
    }
  };


  const handleSendEmailRequest = (row) => {
    alert(`Solicitud de enviar correo para ${row.selectedFicha}`);
  };


  const columns = useMemo(() => [
    {
      field: 'actions',
      headerName: '',
      width: 60,
      renderCell: (params) => (
        <>
          <IconButton onClick={(e) => handleMenuOpen(e, params.row)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl && selectedRow?.id === params.row.id)}
            onClose={handleMenuClose}
          >
            <MenuItem disabled>
              <DescriptionIcon fontSize="small" /> {selectedRow?.selectedFicha}
            </MenuItem>
            <MenuItem disabled>
              {selectedRow?.ahorroEnergia} kWh x {offerValue} c€ = <strong>{(selectedRow?.ahorroEnergia * offerValue).toFixed(2)} €</strong>
            </MenuItem>
            <Divider />
            {userRole === 'delegado' && estadoFiltro === 'comprado' ? (
              <MenuItem onClick={() => handleSendEmailRequest(params.row)}>
                <EmailIcon fontSize="small" /> Demanda de Enviar Correo
              </MenuItem>
            ) : userRole === 'delegado' ? (
              <MenuItem>
                <Slider
                  value={offerValue}
                  onChange={handleOfferChange}
                  min={0}
                  max={200}
                  step={1}
                  valueLabelDisplay="auto"
                  sx={{ width: 150, mr: 2 }}
                />
                <TextField
                  label="Oferta"
                  type="number"
                  value={offerValue}
                  onChange={handleOfferChange}
                  size="small"
                  sx={{ width: '90px' }}
                />
                <Typography variant="body2"> c€/kWh</Typography>
                <Button onClick={() => handleOfferSubmit(params.row.id)}>
                  <ShoppingCartCheckoutIcon fontSize="small" /> Ofertar
                </Button>
              </MenuItem>
            ) : null}
            {userRole === 'cedente' && (
              <MenuItem onClick={() => handleDelete(params.row.id)}>
                <DeleteIcon fontSize="small" /> Borrar
              </MenuItem>
            )}
          </Menu>
        </>
      ),
    },
    { field: 'ahorroEnergia', headerName: 'Ahorro Energia', width: 105 },
    { field: 'contraprestacion', headerName: 'c€/kWh', width: 105 },
    {
      field: 'selectedFicha',
      headerName: 'Ficha',
      width: 444,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2em' }}>
          {params.value}
        </div>
      ),
    },
    { field: 'estado', headerName: 'Estado', width: 111 },
    { field: 'selectedComunidad', headerName: 'Comunidad Autónoma', width: 155 },
    { field: 'selectedSector', headerName: 'Sector', width: 145 },
    { field: 'userRef', headerName: 'User Reference', width: 145 },
    {
      field: 'download',
      headerName: 'Descargar',
      width: 120,
      renderCell: (params) => (
        params.row.zipFileUrl ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => window.open(params.row.zipFileUrl, '_blank')}
          >
            Descargar
          </Button>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No disponible
          </Typography>
        )
      ),
    },
  ], [anchorEl, selectedRow, offerValue, userRole, estadoFiltro]);
  

  const columns0 = useMemo(() => [
    {
      field: 'actions',
      headerName: '',
      width: 60,
      renderCell: (params) => (
        <>
          <IconButton onClick={(e) => handleMenuOpen(e, params.row)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl && selectedRow?.id === params.row.id)}
            onClose={handleMenuClose}
          >
            <MenuItem disabled>
              <DescriptionIcon fontSize="small" /> {selectedRow?.selectedFicha}
            </MenuItem>
            <MenuItem disabled>
              {selectedRow?.ahorroEnergia} kWh x {offerValue} c€ = <strong>{(selectedRow?.ahorroEnergia * offerValue).toFixed(2)} €</strong>
            </MenuItem>
            <Divider />
            {userRole === 'delegado' && estadoFiltro === 'comprado' ? (
              <MenuItem onClick={() => handleSendEmailRequest(params.row)}>
                <EmailIcon fontSize="small" /> Demanda de Enviar Correo
              </MenuItem>
            ) : userRole === 'delegado' ? (
              <MenuItem>
                <Slider
                  value={offerValue}
                  onChange={handleOfferChange}
                  min={0}
                  max={200}
                  step={1}
                  valueLabelDisplay="auto"
                  sx={{ width: 150, mr: 2 }}
                />
                <TextField
                  label="Oferta"
                  type="number"
                  value={offerValue}
                  onChange={handleOfferChange}
                  size="small"
                  sx={{ width: '90px' }}
                />
                <Typography variant="body2"> c€/kWh</Typography>
                <Button onClick={() => handleOfferSubmit(params.row.id)}>
                  <ShoppingCartCheckoutIcon fontSize="small" /> Ofertar
                </Button>
              </MenuItem>
            ) : null}
            {userRole === 'cedente' && (
              <MenuItem onClick={() => handleDelete(params.row.id)}>
                <DeleteIcon fontSize="small" /> Borrar
              </MenuItem>
            )}
          </Menu>
        </>
      ),
    },
    { field: 'ahorroEnergia', headerName: 'Ahorro Energia', width: 105 },
    { field: 'contraprestacion', headerName: 'c€/kWh', width: 105 },
    {
      field: 'selectedFicha',
      headerName: 'Ficha',
      width: 444,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2em' }}>
          {params.value}
        </div>
      ),
    },
    { field: 'estado', headerName: 'Estado', width: 111 },
    { field: 'selectedComunidad', headerName: 'Comunidad Autónoma', width: 155 },
    { field: 'selectedSector', headerName: 'Sector', width: 145 },
    { field: 'userRef', headerName: 'User Reference', width: 145 },
  ], [anchorEl, selectedRow, offerValue, userRole, estadoFiltro]);

  return (
    <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto' }}>
      {rows.length === 0 ? (
        <Box textAlign="center" p={2}>
          <Alert severity="info">No hay elementos disponibles.</Alert>

        </Box>
      ) : (
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pageSizeOptions={[10, 20, 50]}
          disableColumnResize
          density="compact"
        />
      )}
    </div>
  );
}
