
/* 
 Explanation

cambiar  la asignacon del campo selectedFicha para que la probabilidad sea de [10,15,25,50] % segun en FICHA  tenga contenida la palabra [envolvente,bomba,Adquisición,Sustitución]
ChatGPT dijo:
ChatGPT
To modify the assignment of the selectedFicha field so that it selects different fichas based on specified probabilities associated with certain keywords (like "envolvente," "bomba," "Adquisición," and "Sustitución"), you can implement a conditional check for each keyword and assign probabilities accordingly.





To assign selectedComunidad with a probability inversely proportional to its order in the comunidadesAutonomas list, we can adjust the probability of selection by giving items later in the list a higher likelihood of being chosen.






Filter fichas based on keywords.
Assign probabilities for selection.
Here's the updated code for the selectedFicha assignment inside the generarDocumentos

Filtering fichas: The code filters ActuacionesEstandarizadas to create an array of fichas that contain any of the specified keywords.
Assigning selectedFicha: Depending on the random value generated, it checks against the thresholds and selects the appropriate ficha based on the keywords.
Use of ?.: This ensures that if no ficha is found for a keyword, it doesn’t throw an error; it will simply assign undefined to selectedFicha.


loading State: Prevents the button from being clicked multiple times during document creation by disabling it.
Button Text: Displays "Generando Documentos..." while generating and reverts to "Crear 100 Documentos" after completion.
This structure should handle your needs efficiently, including basic error logging and user feedback during processing. Let me know if you need further customization!


generarEstadoAleatorio function: This function uses a random number between 0 and 100 to assign estado with the following logic:
< 25%: Assign 'ofertado'.
< 95% (25% to 95%): Assign 'comprado'.
>= 95% (5%): Assign 'borrado'.
This distribution will yield approximately the specified probabilities.



generarUserRefAleatorio function: This function assigns userRef based on the following probability ranges:
< 10%: 4effPKqK6ph8VugD6Oi6JCxXdmI3
< 40% (10% to 40%): a5aUUwJYtcTH81yQPJfXVXW630P2
>= 40% (remaining 60%): fUcwycjqdaUpzo2wRDJVvvtIcch1
This approach ensures approximately the specified probabilities for each userRef.






 To add the fechaRealizacion field with dates within the last 12 months, distributed in reverse chronological order (so recent months have lower percentages and earlier months have higher percentages), we can calculate percentages inversely proportional to each month's distance from the current month.

Here's the updated code implementing fechaRealizacion:
generarFechaRealizacion function:
Calculates the last 12 months starting from the current month.
Uses reverse-proportional weights so earlier months have a higher probability.
Uses a cumulative approach to check if a randomly generated weight falls within a given month’s range.


*/












import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import Comunidades from '../data/Comunidades';
import ActuacionesEstandarizadas from '../data/ActuacionesEstandarizadas';

const GenerarDocumentos = () => {
  const [loading, setLoading] = useState(false);

  const generarEstadoAleatorio = () => {
    const random = Math.random() * 100;
    if (random < 55) {
      return 'ofertado';
    } else if (random < 45) {
      return 'comprado';
    } else {
      return 'comprado';
    }
  };


  // cedentes
  const generarUserRefAleatorio = () => {
    const random = Math.random() * 100;
    if (random < 40) {
      return '4effPKqK6ph8VugD6Oi6JCxXdmI3';
    } else if (random > 40) {
      return 'fUcwycjqdaUpzo2wRDJVvvtIcch1';
    } 
  };

  const generarFechaRealizacion = () => {
    const now = new Date();
    const months = Array.from({ length: 12 }, (_, i) => new Date(now.getFullYear(), now.getMonth() - i, 1));
    const totalWeight = (12 * (12 + 1)) / 2;

    const random = Math.random() * totalWeight;
    let cumulativeWeight = 0;

    for (let i = 0; i < 12; i++) {
      cumulativeWeight += 12 - i;
      if (random < cumulativeWeight) {
        const selectedDate = months[i];
        return `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
      }
    }
  };

  const generarSelectedComunidad = () => {
    const totalPoblacion = Comunidades.reduce((acc, c) => acc + c.poblacion, 0);
    const random = Math.random() * totalPoblacion;
    let cumulativePopulation = 0;

    for (const comunidad of Comunidades) {
      cumulativePopulation += comunidad.poblacion;
      if (random < cumulativePopulation) {
        return comunidad.comunidad;
      }
    }
  };

  const generarDocumentos = async () => {
    setLoading(true);
    for (let i = 0; i < 10; i++) {
      const ahorroEnergia = Math.floor(Math.random() * 2001);
      const contraprestacion = Math.floor(Math.random() * (198 - 80 + 1)) + 80;

      const selectedComunidad = generarSelectedComunidad();
      const actuacionAleatoria = ActuacionesEstandarizadas[Math.floor(Math.random() * ActuacionesEstandarizadas.length)];
      const selectedFicha = actuacionAleatoria.FICHA;
      const selectedSector = actuacionAleatoria.SECTOR;

      const estado = generarEstadoAleatorio();
      const userRef = generarUserRefAleatorio();
      const fechaRealizacion = generarFechaRealizacion();

      try {
        await addDoc(collection(db, 'listings'), {
          ahorroEnergia,
          contraprestacion,
          selectedComunidad,
          selectedFicha,
          selectedSector,
          estado,
          userRef,
          fechaRealizacion,
        });
        console.log(
          `Documento ${i + 1} creado: Ahorro de Energía = ${ahorroEnergia}, Oferta = ${contraprestacion}, Comunidad Autónoma = ${selectedComunidad}, Ficha = ${selectedFicha}, Sector = ${selectedSector}, Estado = ${estado}, UserRef = ${userRef}, Fecha Realizacion = ${fechaRealizacion}`
        );
      } catch (error) {
        console.error('Error al crear documento: ', error);
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>Generar Documentos en Firestore</h1>
      <button onClick={generarDocumentos} disabled={loading}>
        {loading ? "Generando Documentos..." : "Crear 10 Documentos"}
      </button>
    </div>
  );
};

export default GenerarDocumentos;
