// Documentation.js
import React from 'react';

const Documentation = () => {
  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <iframe
        src="mkdocsAyuda/site/index.html" // Path to your MkDocs documentation
        
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Documentation"
      />
    </div>
  );
};

export default Documentation;
