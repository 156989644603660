import React, { useState, useEffect, useRef } from 'react';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { saveAs } from 'file-saver';
import debounce from 'lodash.debounce';
import { TextField, Button, CircularProgress, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const FillPdfForm = () => {
  const [formData, setFormData] = useState({
    ti: '',
    te: '',
    h: '',
  });
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const pdfDocRef = useRef(null); // Referencia al PDF cargado

  const debouncedFillPdfAndPreview = debounce(async (data) => {
    setLoading(true);
    try {
      const existingPdfBytes = await fetch('/fichas/agrario/AGR010.pdf').then((res) => res.arrayBuffer());

      if (!pdfDocRef.current) {
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDocRef.current = pdfDoc;
      }

      const pdfDoc = pdfDocRef.current;
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const form = pdfDoc.getForm();

      const tiField = form.getTextField('ti');
      const teField = form.getTextField('te');
      const hField = form.getTextField('h');

      tiField.setText(data.ti);
      teField.setText(data.te);
      hField.setText(data.h);

      tiField.updateAppearances(helveticaFont);
      teField.updateAppearances(helveticaFont);
      hField.updateAppearances(helveticaFont);

      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      setPdfPreviewUrl(url);
    } catch (error) {
      console.error("An error occurred while filling the PDF:", error);
      alert("Failed to generate PDF preview. Please try again.");
    } finally {
      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    debouncedFillPdfAndPreview(formData);
    return () => debouncedFillPdfAndPreview.cancel();
  }, [formData]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const downloadPdf = () => {
    if (pdfPreviewUrl) {
      saveAs(pdfPreviewUrl, 'formulario_rellenado.pdf');
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Rellenar Formulario PDF
      </Typography>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {pdfPreviewUrl && (
        <>
          <Typography variant="h5" component="h2">
            PDF Preview:
          </Typography>
          <Box sx={{ cursor: 'pointer', width: '100%' }} onClick={handleOpenModal}>
            <Worker workerUrl="/workers/pdf.worker.min.js">
              <Viewer
                fileUrl={pdfPreviewUrl}
                style={{
                  width: '100%',
                  height: '100%',
                  margin: 0,
                  padding: 0
                }}
              />
            </Worker>
          </Box>
        </>
      )}
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mb: 2 }}
        onClick={downloadPdf}
      >
        Descargar PDF Rellenado
      </Button>

      {/* Modal de formulario */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Rellenar Formulario</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="TI"
            name="ti"
            variant="outlined"
            value={formData.ti}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="TE"
            name="te"
            variant="outlined"
            value={formData.te}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="H"
            name="h"
            variant="outlined"
            value={formData.h}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              debouncedFillPdfAndPreview(formData);
              handleCloseModal();
            }}
            color="primary"
            variant="contained"
          >
            Rellenar y Ver PDF
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FillPdfForm;
