import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import datos from './AgentesObligados.json';

const columns = [
    { field: 'NIF', headerName: 'NIF', width: 150 },
    { field: 'RazonSocial', headerName: 'Razón Social', width: 250 },
    { field: 'Ventas2022', headerName: 'Ventas 2022 (GWh)', width: 200 },
    { field: 'ObligacionAhorro2024', headerName: 'Obligación Ahorro 2024 (GWh)', width: 250 },
    { field: 'ImporteMinimoFNEE', headerName: 'Imp. min. FNEE con CAE (Euros)', width: 250 }
];

const AgentesObligados = () => {
    // Transformar los datos para incluir un campo `id` único (necesario para el DataGrid)
    const rows = datos.map((item, index) => ({
        id: index + 1, // Genera un ID único basado en el índice
        NIF: item.NIF,
        RazonSocial: item["Razon social"],
        Ventas2022: item["Ventas 2022 (GWh)"],
        ObligacionAhorro2024: item["Obligacion ahorro 2024 (GWh)"],
        ImporteMinimoFNEE: item["Importe minimo FNEE con CAE (Euros)"]
    }));

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                sortModel={[
                    { field: 'ImporteMinimoFNEE', sort: 'desc' } // Ordenar por defecto por la columna ImporteMinimoFNEE de forma ascendente
                ]}
            />
        </div>
    );
};

export default AgentesObligados;
