import React, { useState } from 'react';
import { db } from '../firebase.config'; // Asegúrate de importar tu configuración de Firebase
import { collection, getDocs, addDoc } from 'firebase/firestore';

const CopyListings = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const copyCollection = async () => {
    setLoading(true);
    setMessage('Copia en progreso...');

    try {
      const listingsCollection = collection(db, 'listings');
      const listingsSnapshot = await getDocs(listingsCollection);

      const promises = listingsSnapshot.docs.map(async (doc) => {
        const data = doc.data();

        // Eliminar los campos 'userRef' y 'comprador'
        const { userRef, comprador, ...rest } = data;

        // Agregar los datos restantes a la nueva colección
        await addDoc(collection(db, 'listings_copy'), {
          ...rest,
          // originalId: doc.id // Opcional: conservar el ID del documento original
        });
      });

      // Esperar a que se completen todas las promesas
      await Promise.all(promises);
      setMessage('Copia completada');
    } catch (error) {
      console.error("Error al copiar la colección: ", error);
      setMessage('Error al realizar la copia. Intenta de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Copia de la colección Listings</h1>
      <button onClick={copyCollection} disabled={loading}>
        {loading ? 'Copiando...' : 'Hacer copia de Listings'}
      </button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default CopyListings;
