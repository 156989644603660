import React, { useState } from 'react';


const EnviaCorreo = () => {

  const [emailData, setEmailData] = useState({
    to: '',
    subject: '',
    text: '',
  });

  const handleChange = (e) => {
    setEmailData({
      ...emailData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailData),
      });
      if (response.ok) {
        alert('Email sent successfully!');
      } else {
        alert('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <div>
      <h2>Send Email</h2>
      <p>arrncar express >>> node server.js </p>
      <form onSubmit={handleSubmit}>
        <input name="to" placeholder="Recipient Email" onChange={handleChange} />
        <input name="subject" placeholder="Subject" onChange={handleChange} />
        <textarea name="text" placeholder="Message" onChange={handleChange} />
        <button type="submit">Send Email</button>
      </form>
    </div>
  );
}


export default EnviaCorreo;
